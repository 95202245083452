import PhotoGallery from "../../../src/components/PhotoGallery";
import ImageWrap from "../../../src/components/ImageWrap";
import YoutubeVideo from "../../../src/components/YoutubeVideo";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  PhotoGallery,
  ImageWrap,
  YoutubeVideo,
  React,
  MDXTag
};