import PhotoGallery from "../../../src/components/PhotoGallery";
import ImageWrap from "../../../src/components/ImageWrap";
import Carousel from "../../../src/components/Carousel";
import React from 'react';
import { MDXTag } from '@mdx-js/tag';
export default {
  PhotoGallery,
  ImageWrap,
  Carousel,
  React,
  MDXTag
};